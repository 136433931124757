/*
 * @Author: ringo
 * @Date: 2021-08-31 15:25:46
 * @LastEditTime: 2022-08-10 14:40:59
 * @LastEditors: Mozixin
 * @Description:
 * @FilePath: \foundao_work\xiangan_enterprise\src\models\index.ts
 */
// 全局状态
 
 

type action = {
  payload: object | undefined;
  type: string;
};


const initialValue ={
  token:null,
  userInfo:null
}
 

export default  {
    namespace: 'global',
    state: {
      ...initialValue
    },
    reducers: {
      save(state: Object, { type,payload }:action) {
        return {
          ...state,
          ...payload,
        };
      },
      clearAll(){
        return {
          ...initialValue
        }
      },
      logout(state: Object, { type, payload }: action){
        return{
          ...state,
          token: null,
          userInfo: null
        }
      }
    },
    effects: {},
    subscriptions: { },
  }
 

   
// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/Users/mars/code/project/qfj-doctor/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Basics__Login' */'@/pages/Basics/Login')}),
    "title": "登录",
    "exact": true
  },
  {
    "path": "/404",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Basics__404' */'@/pages/Basics/404')}),
    "exact": true
  },
  {
    "path": "/home",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__basicLayouts__index_doctor' */'@/layouts/basicLayouts/index_doctor')}),
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/wrappers/auth')})],
    "routes": [
      {
        "path": "/home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__UserBehavior__index' */'@/pages/Dashboard/UserBehavior/index')}),
        "exact": true,
        "title": "用户行为看板"
      }
    ]
  },
  {
    "path": "/lc",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__basicLayouts__index_linchuang' */'@/layouts/basicLayouts/index_linchuang')}),
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/wrappers/auth')})],
    "routes": [
      {
        "path": "/lc",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__TrainData_lc__index' */'@/pages/Dashboard/TrainData_lc/index')}),
        "exact": true,
        "title": "临床数据"
      }
    ]
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__basicLayouts__index' */'@/layouts/basicLayouts/index')}),
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/wrappers/auth')})],
    "routes": [
      {
        "path": "/dashboard",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__TrainData__index' */'@/pages/Dashboard/TrainData/index')}),
        "exact": true,
        "title": "数据看板",
        "menu": true,
        "dashboard": true
      },
      {
        "path": "/dashboard/product",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__Product__index' */'@/pages/Dashboard/Product/index')}),
        "exact": true,
        "title": "产品数据看板",
        "menu": true,
        "dashboard": true
      },
      {
        "path": "/dashboard/userBehavior",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__UserBehavior__index' */'@/pages/Dashboard/UserBehavior/index')}),
        "exact": true,
        "title": "用户行为看板",
        "menu": true,
        "dashboard": true
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Basics__404' */'@/pages/Basics/404')})
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}

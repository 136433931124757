/*
 * @Descripttion:
 * @version:
 * @Author: Mozixin
 * @Date: 2022-08-09 14:05:03
 * @LastEditors: Mozixin
 * @LastEditTime: 2022-11-25 15:57:07
 */
/*
 * @Author: ringo
 * @Date: 2021-10-15 14:19:37
 * @LastEditTime: 2022-05-05 15:43:34
 * @LastEditors: Mozixin
 * @Description:
 * @FilePath: \qifujia-work\qfj-doctor\src\app.tsx
 */
// 运行时配置 参考文档
import React from 'react';
import { message, notification } from 'antd';
import { createLogger } from 'redux-logger';
import { RequestConfig, history, getDvaApp } from 'umi';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // 用什么缓冲 storage 还是session
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { REQUEST_URL } from './config';

const codeMessage: {
  [key: number]: string;
} = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  405: '错误的请求方法。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

interface error {
  name: string;
  data: any;
  type: string;
  response: {
    status: number;
    statusText: string;
    url: string;
  };
}

// 请求全局配置
export const request: RequestConfig = {
  prefix: '',
  timeout: 60000,
  // 错误处理
  errorHandler(error: error) {
    console.log(error, 'resp error handle');
    if (error.name === 'BizError') {
      notification.error({
        message: `请求错误 ${error.data.code}`,
        description: error.data.msg,
      });
      history.replace(
        `/login?callback=${encodeURIComponent(
          history.location.pathname ?? '' + history.location.search ?? '',
        )}`,
      );
      // return error.data.code;
    }
    const { response } = error;
    if (response) {
      const errortext = codeMessage[response.status] || response.statusText;
      const { status, url } = response;
      // token过期 未授权
      const dva = getDvaApp();
      if (status === 401) {
        dva._store.dispatch({
          type: 'global/clearAll',
          payload: {},
        });
        // history.replace('/login?callback='+ encodeURIComponent(window.location.href));
        history.replace(
          `/login?callback=${encodeURIComponent(
            history.location.pathname ?? '' + history.location.search ?? '',
          )}`,
        );
      }
      notification.error({
        message: `请求错误 ${status}: ${url}`,
        description: errortext,
      });
    }
    return Promise.reject();
  },

  errorConfig: {
    /**
     * 错误数据适配器
     * 使用useRequest时 errorConfig 与 中间件 无法透传
     * 仅在使用 umi自带request时生效
     *
     */
    adaptor: (resData) => {
      // console.log(resData, '返回适配');
      return {
        ...resData,
        success: resData.ok,
        errorMessage: resData.message,
      };
    },
  },
  // 中间件
  middlewares: [],
  // 全局请求拦截器
  requestInterceptors: [
    (url, options) => {
      // console.log(url, options, '请求拦截器');
      const dva = getDvaApp();

      const token = dva._store.getState()?.global?.token;
      options.headers = {
        ...options.headers,
        Authorization: token,
        client: 'PC',
        // connection: 'keep-alive'
      };
      return {
        url: REQUEST_URL + url,
        options,
      };
    },
  ],
  /**
   * 全局返回拦截器
   * 执行顺序优先于RequestConfig中的错误处理
   * 如果http状态码不为200则进入错误处理
   * 如果为200直接在拦截器中判断data中的code进行自定义错误处理
   */
  responseInterceptors: [
    async (response, options) => {
      // console.log(response, '返回拦截器');
      /**
       * http 错误状态处理
       * 不为200 进入errorHandler处理
       */
      const { status, statusText, url } = response;
      if (status !== 200) {
        return response;
      } else {
        const data = await response.clone().json();

        const { code } = data;
        switch (code) {
          case 200:
            return Promise.resolve(data);
          case 300:
            // 信息错误 交于页面处理
            console.log('接口', '信息已存在', code, data);
            return Promise.resolve(data);
          case -99:
            console.log('接口', '接口无权限', code, data);
            message.error(data.message || '接口无权限');
            return Promise.reject('接口无权限');
          case -1:
            console.log('接口', '请求错误', code, data);
            message.error(data.message || '请求失败，请稍后再试');
            return Promise.reject(data);
          case 0:
            console.log('接口', '登录失效', code, data);
            message.error(data.message || '登录失效');
            return Promise.reject('登录失效');
          default:
            console.log('接口', '未知错误code', code, data);
            message.error('接口', data.message || '未知错误code');
            Promise.reject(undefined);
        }

        // 登录失效返回全部信息 至全局接口完成处做处理 因为http状态为401故进http错误处理
        // if (data?.code == 0) {
        //   message.error('登录失效');
        //   localStorage.removeItem('userInfo')
        //   localStorage.removeItem('token')
        //   history.replace('/login')
        //   return response
        // }
      }
      return response;
    },
  ],
};

const persistConfig = {
  key: 'root', // 自动框架生产的根目录id 是root。不变
  storage, // 这个是选择用什么存储，session 还是 storage
};

const persistEnhancer =
  () =>
  (createStore: any) =>
  (reducer: any, initialState: any, enhancer: any) => {
    const store = createStore(
      persistReducer(persistConfig, reducer),
      initialState,
      enhancer,
    );
    const persist = persistStore(store);
    return { ...store, persist };
  };

export const dva = {
  config: {
    onAction: createLogger(), // 每次action的时候会触发
    onError(e: Error) {
      message.error(e.message, 3);
    },
    extraEnhancers: [persistEnhancer()],
  },
};

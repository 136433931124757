/*
 * @Descripttion:
 * @version:
 * @Author: Mozixin
 * @Date: 2022-08-09 14:06:39
 * @LastEditors: Mozixin
 * @LastEditTime: 2022-08-10 11:41:06
 */
const { BUILD_ENV, NODE_ENV } = process.env;
let _host = '';
let _path = '/api';

if (NODE_ENV == 'development') {
  _host = '';
} else {
  _host =
    BUILD_ENV === 'prod'
      ? 'https://a.jidihudong.com'
      : 'https://a-develop.jidihudong.com';
}

export const REQUEST_VERSION = '/v1';
export const REQUEST_HOST = _host;
export const REQUEST_PATH = _path;
export const REQUEST_URL = REQUEST_HOST + REQUEST_PATH;
// export const
